.page-container {
  display: flex;
  flex-direction: column;
}

.footer {
  background-color: #2185d0;
  color: white;
  padding: 10px;
  
  position: relative;
  bottom: 0;
  width: 100%;
  clear: both;
  margin-top: 90px;
}
